import React, { Component } from 'react';
import Categories from '../components/Categories';
import Service from '../components/Service';
import { Helmet } from 'react-helmet';


class Shipping extends Component{

    render(){
        return(
            <div>
                <Helmet>
                    <title>{Service.websiteTitleName} | Dostava</title>
                </Helmet>

                <div className="left-content">
                    <h3>Kategorije</h3>
                    <Categories />
                </div>
                <div className="right-content">
                    <h2>
                        Dostava
                    </h2>
                    <h3>
                        Troškovi isporuke i načini dostave:
                    </h3>
                    <p>
                        Knjige je trenutno moguće slati jedino na teritoriji Republike Srbije.
                        <br />
                        Troškovi dostave: Za naručene knjige u vrednosti do 3000 dinara troškovi iznose {Service.shippingFee} dinara, a za porudžbine preko 3000 dinara dostava je besplatna.
                        <br />
                        Plaćanje poručenih knjiga vrši se na dva načina:
                        <br />

                        1)      Pouzećem: plaćate celokupan iznos kuriru prilikom dostave;
                        <br />
                        2)      Onlajn: plaćate karticom prilikom poručivanja.
                        <br />
                        Rok za isporuku je 1-3 radna dana u period od 8 do 16h; vikendom kurirska služba ne isporučuje. Na dan isporuke dobićete SMS poruku od kurirske službe da očekujete pošiljku.
                        <br />
                        Ukoliko želite da naručite knjige iz inostranstva, pišite nam na mejl : office@publikpraktikum.rs 
                    </p>
                </div>
            </div>
        );
    }

}

export default Shipping;