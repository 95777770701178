import React, { Component, Fragment } from 'react';
import { Cart } from '../components/Cart';
import Categories from '../components/Categories';
import Service from '../components/Service';
import StarRatings from 'react-star-ratings';
// import {BrowserRouter as Router, Route, Link, Read} from 'react-router-dom';
import {Auth} from '../components/Auth';
import {Config} from '../components/Config';
import Sticker from '../components/Sticker';
// import Pager from '../components/Pager';
import ReactPaginate from 'react-paginate';
import duix from 'duix';
import {NotificationManager} from 'react-notifications';
import {withRouter} from 'react-router-dom';
import { Helmet } from 'react-helmet';
import {Link} from 'react-router-dom';
import renderHTML from 'react-render-html';
//import ReactGA from 'react-ga';



const queryString = require('query-string');

function capitalizeFirstLetterCategory(string) {
    if(string){
        let check = parseInt(string.charAt(0));
        if(isNaN(check)){
            let r = string.replace(/-/g,' ');
            return r.charAt(0).toUpperCase() + r.slice(1);
        }
        return string;
    }

    return 'Knjige';
}

function getItemDiscount (item){
    //(40-30)/40 * 100;
    if(!item.discount){
        return Math.round((parseInt(item.full_price) - parseInt(item.price)) /  parseInt(item.full_price) * 100);
    }
    return parseInt(item.discount);
}


// const Info = props => (
  
//     <div className="product-info"><span className="product-info-label" >{props.label}: </span> {props.value} </div>
 
// );

// function images(image){
//     return 'http://api.pp.cekaonica.com/media/'+ image + '.jpg';
//   }

class Products extends Component {

    

    constructor(props) {
        super(props);
        this.state = {
            products: [],
            numberOfProducts: 0,
            page: 1,
            perPage: Config.perPage,
            currentFilter: '',
            pageUpNumber: 0,
            pageDownNumber: 0,
            noProductsMessage: 'Učitavanje',
            categories: [],
            categoryDescription: null
        }
        
    }

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            //console.log('route change');
            this.updatePage();
        }
    }

    

    componentDidMount=() =>{
        
        this.updatePage();
        this.getAllCategories();

    }

    updatePage=()=>{
        let getParams = queryString.parse(this.props.location.search);
        if(getParams.page){
            this.page = parseInt(getParams.page);
        }else{
            this.page = 1;
        }

        setTimeout(()=>{
            this.updateProducts();
        },100);
    }



    componentWillReceiveProps(newProps){
        let getParams = queryString.parse(newProps.location.search);
        if(getParams.page){
            this.page = parseInt(getParams.page);
        }else{
            this.page = 1;
        }
        
        // if(newProps.match.params.filter){
        //     this.filter = newProps.match.params.filter;
        // }
        // if(newProps.match.params.a){
        //     this.params.a = newProps.match.params.a;
        // }
        // if(newProps.match.params.b){
        //     this.params.a = newProps.match.params.b;
        // }
        setTimeout(()=>{
           // this.updateProducts();
        },100);

    }


    addToCart=(productId, product)=>{
      Cart.addToCart(productId, product);
      Cart.getCart();
    }

    removeOneFromCart(product){
      Cart.removeOneFromCart(product);
      Cart.getCart();
    }
  
    removeFromCart(product){
      Cart.removeFromCart(product);
      Cart.getCart();
    }

    getParams = queryString.parse(this.props.location.search);
    page;
    filter;
    params = {};

    compareByStatus(a, b) {
        const bandA = a.status;
        const bandB = b.status;

        let comparison = 0;
        if (bandA > bandB) {
            comparison = 1;
        } else if (bandA < bandB) {
            comparison = -1;
        }
        return comparison;
    }

    sortProducts(products){
        // let p = products;
        let a = [];
        let b = [];
        for(var i in products){
            if(products[i].status >= 3){
                b.push(products[i])
            }else{
                a.push(products[i])
            }
        }
        a.sort((a, b) => (a.published_at < b.published_at) ? 1 : -1);
        b.sort((a, b) => (a.published_at < b.published_at) ? 1 : -1);

        return a.concat(b);
    }

    addToWishlist(product){

    if(Auth.isAuthenticated){
        Service.addToWishlist({
            productId: product.id, 
            Auth
            },()=>{
                duix.set('wishlistItems', 1);
                duix.set('modal', 'Proizvod je dodat u listu želja.');
            });
        }else{
            alert('Morate biti ulogovani da biste koristili listu želja.');
        }
    }


    updateProducts = () => {
        // get products without any filter
        //console.log('UPDATE');
        this.setState({products: [], noProductsMessage: 'Učitavanje...'});

        if(!this.props.match.params.filter){
            if(this.state.currentFilter !== 'default'){
                // this.state.currentFilter = 'default';
                this.setState({currentFilter: 'default'})
                this.setState({page: 1})
            }
            // let getParams = queryString.parse(this.props.location.search);
            Service.get('/products/get',{page: this.page, perPage: this.state.perPage},(res)=>{
                let p = res.data.products;
                //p.sort((a, b) => (a.published_at < b.published_at) ? 1 : -1);
                //p.sort((a, b) => (a.status > b.status) ? 1 : -1);
                this.setState({products: p});
                //google send products
                // window.dataLayer.push({
                //     'ecommerce': {
                //         'currencyCode': 'RSD',                       // Local currency is optional.
                //         'impressions': [
                //             {
                //             'name': 'Vojno Test',       // Name or ID is required.
                //             'id': '12345',
                //             'price': '15.25',
                //             'brand': 'Google',
                //             'category': 'Apparel',
                //             'variant': 'Gray',
                //             'list': 'Default',
                //             'position': 1
                //             },
                //             {
                //             'name': 'Vojno Drugi Test',
                //             'id': '67890',
                //             'price': '33.75',
                //             'brand': 'Publik',
                //             'category': 'Books',
                //             'list': 'Default',
                //             'position': 2
                //             }
                //         ]
                //     }
                // });
                this.setState({numberOfProducts: res.data.total_products});
                //this.setState({noProductsMessage: 'Nema pronađenih proizvoda!'});
                if(res.data.products){
                    this.setState({noProductsMessage: 'Nema pronađenih proizvoda!'});
                } else {
                    this.setState({noProductsMessage: ''});
                }
                // this.setState({pageUpNumber: this.pageUp(), pageDownNumber: this.pageDown()},()=>{
                // });
            });
            return;
        }
        
        //  search
        if(this.props.match.params.filter === 'filter'){
            if(this.state.currentFilter !== 'filter'){
                this.setState({currentFilter: 'filter'})
                this.setState({page: 1})
            }
            Service.getProductBySearch({filter: this.props.match.params.a, perPage: this.state.perPage, page: this.page},(res)=>{
                let p = res.data.products;

                // parse products for fb-pixel
                let productIds = [];
                p.map(item => {
                    productIds.push(item.id)
                })

                window.fbq('track', 'Search', {
                    search_string: this.props.match.params.a, 
                    content_category: 'Knjige',
                    content_ids: productIds,
                    content_type: 'product'
                });
                window.gtag('event', 'search', {
                    "search_term": this.props.match.params.a
                });
                this.setState({products: this.sortProducts(p)});
                this.setState({numberOfProducts: res.data.total_products});
                if(res.data.products){
                    this.setState({noProductsMessage: 'Nema pronađenih proizvoda!'});
                } else {
                    this.setState({noProductsMessage: ''});
                }
                // this.setState({pageUpNumber: this.pageUp(), pageDownNumber: this.pageDown()});
            });
            return;
        }

        // get products by category 
        if(this.props.match.params.filter === 'kategorije'){
            if(this.state.currentFilter !== 'kategorije'){
                // this.state.currentFilter = 'kategorije';
                this.setState({currentFilter: 'kategorije'})
                this.setState({page: 1})
            }
            Service.get('/products/getByCategory',{
                    page: this.page, 
                    perPage: this.state.perPage,
                    categoryId: this.props.match.params.a
                    
                    
                },
                (res)=>{

                    // get brand subcategories
                    let s = {};
                    let subcategories = [];
                    for(var c in this.state.categories){
                        if(this.state.categories[c].name === 'Brend'){
                            s = this.state.categories[c].subcategories;
                        }
                    }

                    for(var i in s){
                        subcategories.push(s[i].id); 
                    }

                    let p = res.data.products;
                    let n = [];
                    if(!subcategories.includes(parseInt(this.a))){
                        n = p; //this.sortProducts(p);
                    }else{
                        n = p;
                    }
                    this.setState({products: n, categoryDescription: res.data.category_description});
                    this.setState({numberOfProducts: res.data.total_products});
                if(res.data.products){
                    this.setState({noProductsMessage: 'Nema pronađenih proizvoda!'});
                } else {
                    this.setState({noProductsMessage: ''});
                }
                // this.setState({pageUpNumber: this.pageUp(), pageDownNumber: this.pageDown()});
            });
            return;
        }

        
        
      }
      getParams = queryString.parse(this.props.location.search);


    getAllCategories = () => {

        Service.get('/categories/all',{
        },(res)=>{
            this.setState({categories: res.data},()=>{
                this.updateProducts();
            });
        });
    }

    changeRating=(rate, name)=>{
        if(Auth.isAuthenticated){
            Service.rateProduct({Auth, productId: name, rating: rate},(res)=>{
                this.updateProducts();
            });
        }else{
            NotificationManager.error('Morate biti ulogovani da biste ocenili proizvod! Kliknite ovde da se ulogujete.', 'Greska:', 5000, () => {window.location='/registracija'});
            // alert('Morate biti ulogovani da biste ocenili proizvod!');
        }
    }

    changePage = (page) => {
        //history.push('/test');
        //console.log('location', this.props.location);
        let link;
        if(page.selected){
            //link = `${window.location.href.split('?')[0]}?page=${page.selected + 1}`;
            //link = `/proizvodi?page=${page.selected + 1}`;
            //link = `/proizvodi?page=${page.selected + 1}`;
            link = `${this.props.location.pathname}?page=${page.selected + 1}`;
            
           // window.location.href = link;
        }else{
            //link = `${window.location.href.split('?')[0]}`;
            //link = `/proizvodi`;
            link = this.props.location.pathname;
            
        }
        this.props.history.push(link);
        //this.props.history.push(link);
        //window.location.href = link;
    }

    authorNameLink=(authorName)=>{
        let parseAuthorName = authorName.toLowerCase().replace(/ /g, "%20");
        let link = '/proizvodi/filter/' + parseAuthorName;
        
        return link;
    }

    render() {
        return(
            <div>
                <Helmet>
                    <title>{Service.websiteTitleName} | Proizvodi</title>
                </Helmet>

                <div className="left-content">
                    {/* <h3>Kategorije</h3> */}
                    <Categories />
                </div>
                <div className="right-content">
                <h3>{capitalizeFirstLetterCategory(this.props.match.params.b)}</h3>
                {this.state.categoryDescription && this.props.match.params.filter === 'kategorije' && 
                    <div className="products-category-description">{renderHTML(this.state.categoryDescription)}</div>
                }
                    {this.state.products.map(function(item, index){
                        let stickerNew = false;
                        let bookIndex = index;
                        return(
                            <div className="product-container" key={index} >
                                {/* Stickers */}
                                {/* {item.categories &&
                                    item.categories.map(function(categories, index){
                                        return(
                                            <span key={index}>
                                                {categories.name === "Novi naslovi" && bookIndex < 8 && this.page == 1 &&
                                                    <Fragment>{stickerNew = true}</Fragment>
                                                }       
                                            </span>
                                        )}, this)
                                    
                                } */}

                                {/* {item.sticker == 1 && */}
                                    <div className="stickers-container products-stickers">
                                        {item.new === 1 &&
                                            <Sticker color="green" text="NOVO"/>
                                        }
                                        {item.total_discount &&
                                            // <Sticker color="green" text={`${item.discount}%`} />
                                            <Sticker color="red" text={`${item.total_discount}%`} />
                                        }
                                        { (item.free_shipping == 1 || parseInt(item.price) > 3000) &&
                                            <Sticker color="blue" text="besplatna isporuka" paragraphId="free-shipping"/>
                                        }
                                        {/* {item.preorder == 1 &&
                                            <Sticker color="orange" text="Pretprodaja" fontSize="small"/>
                                        } */}
                                        {/* <Sticker color="turqoise" text="multi"/> */}
                                        
                                        {item.discounts && item.discounts.length > 0 &&
                                            <Sticker image={item.discounts[0].image} />
                                        }
                                        {item.actionSticker  &&
                                            <Sticker image={item.actionSticker} link={item.actionUrl}/>
                                        }
                                    </div>
                                {/* } */}
                                
                                
    

                                <div className="product-image-container">
                                {/* <a href={`/proizvod/${item.id}/${item.slug}`}><img alt="" src={Service.images(item.slug.toLowerCase() + '.jpg')} /></a> */}
                                <Link to={`/proizvod/${item.id}/${item.slug}`}><img alt="" src={Service.images(item.slug.toLowerCase() + '.jpg')} /></Link>
                                </div>
                                {item.preorder == 1 &&
                                    // <Sticker color="orange" text="Pretprodaja" fontSize="small"/>
                                    <div className="preorder">U pretprodaji</div>
                                }

                                <h3>{item.title}</h3>
                                <div className="product-container-info">
                                    <div className="product-authors">
                                        {item.authors.map(function(author, index){
                                            return(
                                                <span key={index} className="authors-span">
                                                    {author.name &&
                                                        <a href={this.authorNameLink(author.name)}><h4>{author.name}</h4></a>
                                                    }
                                                    {/* {!author.link &&
                                                        <h4>{author.name}</h4>
                                                    } */}
                                                </span>
                                            )}, this)
                                        }
                                    </div>
                                     
                                    { (item.full_price !== item.price && item.status !== 2) &&
                                        <span>
                                            <p className="old-price">{Service.price(item.full_price)}</p>
                                            <p>{Service.price(item.price)} </p>
                                        </span>
                                    }
                                    {(item.full_price === item.price && item.status !== 2) &&
                                        <p>{Service.price(item.full_price)}</p>
                                    }
                                    { item.status === 1 && item.stock > 0  &&
                                        <button type="button" className="add-to-cart-button" onClick={()=>{this.addToCart(item.id, item)}}>DODAJ U KORPU</button>
                                    }
                                    { item.status === 2 &&
                                        <fragment>
                                        <p>U pripremi</p>
                                        <button type="button" className="add-to-cart-button wishlist-button" onClick={()=>{this.addToWishlist(item)}}>DODAJ U LISTU ŽELJA</button>
                                        </fragment>
                                    }
                                    { ( parseInt(item.status) === 3 || item.stock <= 0 ) &&
                                        <button type="button" className="add-to-cart-button sold-out" >RASPRODATO</button>
                                    }
                                    {/* <p>{item.published_at}</p> */}
                                    { item.status !== 2 && Service.storeId != 2 &&
                                        <StarRatings
                                        // rating={this.state.rating}
                                        //rating={item.rating}
                                        rating={item.rating}
                                        starDimension={window.innerWidth > 720 ? "1.6em" : "1em"} 
                                        starSpacing={window.innerWidth > 720 ? ".2em" : ".1em"}

                                        starRatedColor="#e6bc00"
                                        changeRating={this.changeRating}
                                        numberOfStars={5}
                                        name={item.id.toString()}
                                        />
                                    }
                                    { item.status !== 2 && Service.storeId == 2 &&
                                        <StarRatings
                                        // rating={this.state.rating}
                                        //rating={item.rating}
                                        rating={item.rating}
                                        starDimension={window.innerWidth > 720 ? "1.2em" : "1em"} 
                                        starSpacing={window.innerWidth > 720 ? ".1em" : ".1em"}
                                        starRatedColor="#e6bc00"
                                        changeRating={this.changeRating}
                                        numberOfStars={5}
                                        name={item.id.toString()}
                                        svgIconViewBox = {'0 0 231 231'}
                                        svgIconPath={'M 186.4 133.75 L 231.7 115.85 186.4 98 Q 168.35 90.9 154.6 77.15 140.85 63.4 133.7 45.3 L 115.8 0 97.95 45.3 Q 90.8 63.4 77.1 77.15 63.35 90.9 45.25 98 L 0 115.85 45.25 133.75 Q 63.35 140.85 77.1 154.6 90.8 168.3 97.95 186.4 L 115.8 231.7 133.7 186.4 Q 140.8 168.35 154.6 154.6 168.3 140.9 186.4 133.75 Z'}
                                        />
                                    }
                                    
                                </div>
                            </div>
                        );
                        },this)
                    }

                    {this.state.products.length < 1 &&
                        <p className="no-products" >{this.state.noProductsMessage}</p>
                    }
                    {/* <Pager numberOfProducts={this.state.numberOfProducts} currentPage={this.page} perPage={Config.perPage}/> */}
                    <ReactPaginate
                        pageCount={Config.getNumberOfPages(this.state.numberOfProducts)}
                        pageRangeDisplayed={window.innerWidth > 720 ? 5 : 1}
                        marginPagesDisplayed={window.innerWidth > 720 ? 3 : 1}
                        previousLabel={'<'}
                        nextLabel={'>'}
                        forcePage={this.page - 1}
                        containerClassName={'pager-container'}
                        activeClassName={'pager-selected'}
                        onPageChange={this.changePage}
                    />
                </div>
                
            </div>

        );
    }

}

export default withRouter(props => <Products {...props}/>);
//export default Products;